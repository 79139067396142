import React, { useEffect, forwardRef } from "react";
import { Link } from "react-router-dom";
// import Rating from "./Rating";
import Pagination from "./pagination";
import { useDispatch, useSelector } from "react-redux";
import { listProduct } from "../../Redux/Actions/ProductActions";
import Loading from "../LoadingError/Loading";
import Message from "../LoadingError/Error";

// Forward ref to the ShopSection component
const ShopSection = forwardRef((props, ref) => {
  const { keyword, pagenumber } = props;
  const dispatch = useDispatch();

  const productList = useSelector((state) => state.productList);
  const { loading, error, products = [], page, pages } = productList;

  useEffect(() => {
    dispatch(listProduct(keyword, pagenumber));
  }, [dispatch, keyword, pagenumber]);

  return (
    <div ref={ref} className="shopsection-container">
    <div className="shop-intro">
        <h2>Explore the World in Graphite</h2>
        <p>
        Discover the magic of pencil art where imagination meets precision. Each piece tells a unique story through intricate strokes and subtle shading. Dive into a world of monochrome beauty and experience art that resonates with depth and emotion. Explore timeless creations crafted with passion and elegance.
        </p>
        <div className="prints-divider"></div>
      </div>
      <div className="section">
        <div className="row">
          <div className="col-lg-12 col-md-12 article">
            <div className="shopcontainer row">
              {loading ? (
                <div className="mb-5">
                  <Loading />
                </div>
              ) : error ? (
                <Message variant="alert-danger">{error}</Message>
              ) : (
                <>
                  {products.map((product) => (
                    <div
                      className="shop shop-container col-lg-4 col-md-6 col-sm-6"
                      key={product._id}
                    >
                      <div className="border-product">
                        <Link to={`/products/${product._id}`}>
                          <div className="shopBack">
                            <img src={product.image} alt={product.name} />
                          </div>
                        </Link>

                        <div className="shoptext">
                          <p>
                            <Link to={`/products/${product._id}`}>
                              {product.name}
                            </Link>
                          </p>

                          {/* <Rating
                            value={product.rating}
                            text={`${product.numReviews} reviews`}
                          /> */}
                          <h3>Ksh {product.price}</h3>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              )}

              {/* Pagination */}
              <Pagination
                pages={pages}
                page={page}
                keyword={keyword ? keyword : ""}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default ShopSection;
