import React, { useRef } from "react";
import Header from "./../components/Header";
import ShopSection from "./../components/homeComponents/ShopSection";
import PortraitsSection from "./../components/homeComponents/PortraitsSection";
import CalltoActionSection from "./../components/homeComponents/CalltoActionSection";
import ContactInfo from "./../components/homeComponents/ContactInfo";
import Footer from "./../components/Footer";

const HomeScreen = ({ match }) => {
  window.scrollTo(0, 0);
  const keyword = match.params.keyword;
  const pagenumber = match.params.pagenumber;

  const shopSectionRef = useRef(null);

  const handleScrollToShop = () => {
    if (shopSectionRef.current) {
      shopSectionRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  const animationKeyframes = `
    @keyframes zoomIn {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.1);
      }
      100% {
        transform: scale(1);
      }
    }
  `;

  const containerStyle = {
    position: 'relative',
    height: '605px',
    overflow: 'hidden',
  };

  const backgroundStyle = {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundImage: 'url("/images/header_img.png")',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    animation: 'zoomIn 10s ease-in-out infinite',
    zIndex: -1,
  };

  const mobileTextStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: '12px',
    color: '#fff',
    textAlign: 'center',
    fontWeight: 'bold',
    display: 'none',
  };

  return (
    <div>
      <style>
        {animationKeyframes}
        
        {`@media (max-width: 768px) {
          .mobile-center-text {
            display: block !important; /* Show text on small screens */
          }
        }`}
      </style>
      {!keyword && (
        <div style={containerStyle}>
          <div style={backgroundStyle}></div>
          <Header className="header" />
          <div className="mobile-center-text" style={mobileTextStyle}>
            Unveiling Beauty Through Art <br/>
          </div>
          <div className="button-container">
            <button 
              type="button" 
              className="shop-my-art-button rounded drop-shadow"
              onClick={handleScrollToShop}
            >
              SHOP NOW
            </button>
          </div>
        </div>
      )}
      {keyword && <Header className="header" />}
      <ShopSection 
        keyword={keyword} 
        pagenumber={pagenumber} 
        ref={shopSectionRef}
      />
      {!keyword && <PortraitsSection />}
      <CalltoActionSection />
      <hr className="mt-4 border-gray-300" />
      <ContactInfo />
      <Footer />
    </div>
  );
};

export default HomeScreen;
