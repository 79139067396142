import React from "react";

const ContactInfo = () => {
  return (
    <div className="contactInfo container">
      <div className="row">
        
        <div className="col-12 col-md-4 contact-Box">
          <div className="box-info">
            <div className="info-image">
              <i className="fas fa-phone-alt"></i>
            </div>
            <h5>
              <a href="tel:+254746003525">Call Me</a>
            </h5>
            <p>+254 746 003 525</p>
          </div>
        </div>

        
        <div className="col-12 col-md-4 contact-Box">
          <div className="box-info">
            <div className="info-image">
              <i className="fas fa-map-marker-alt"></i>
            </div>
            <h5>
              <a href="https://www.google.com/maps?q=Juja,+Kiambu+County,+Kenya" target="_blank" rel="noopener noreferrer">
                Workshop Location
              </a>
            </h5>
            <p>Juja, Kiambu County, Kenya</p>
          </div>
        </div>

        
        <div className="col-12 col-md-4 contact-Box">
          <div className="box-info">
            <div className="info-image">
              <i className="fas fa-envelope"></i>
            </div>
            <h5>
              <a href="mailto:wamaearts@gmail.com">Email</a>
            </h5>
            <p>wamaearts@gmail.com</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;
