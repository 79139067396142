// src/components/homeComponents/PortraitsSection.js
import React from "react";

const PortraitsSection = () => {
  const portraits = [
    { name: "REAL BUGGI", image: "/images/img1.png" },
    { name: "JUST IVY", image: "/images/img2.png" },
    { name: "MORDECAI", image: "/images/img3.png" },
    { name: "RAMMOR", image: "/images/img4.png" },
    { name: "CHARLENE RUTO", image: "/images/img5.png" },
    { name: "BENSOUL", image: "/images/img6.png" },
  ];

  return (
    <div className="portraits-section">
      <h2>PORTRAITS OF PROMINENCE</h2>
      <div className="portraits-grid">
        {portraits.map((portrait, index) => (
          <div key={index} className="portrait-item">
            <img src={portrait.image} alt={portrait.name} />
            <p>{portrait.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PortraitsSection;